<template>
    <div>
         <div class="modal-header">
            <div class="modal-title-my">
                   <h4 v-if="selectedItem.hasOwnProperty('document_name')">{{$t('message.name')}}: {{selectedItem.document_name}}</h4>
                   <h4 v-else>{{$t('message.name')}}: {{ selectedItem.name }}</h4>
            </div>
            <div>
                <el-button @click="close()" type="danger" plain>{{ $t('message.close') }}</el-button>
            </div>
        </div>
        <div class="bodal-body-my" v-if="isShow">
            <div v-show="isVisible">
               <div class="fayil-full">
                <div class="fayil-itme" v-for="(item, index) in building_inform" :key="'item-'+index">
                    <div class="itme-disct-doc">
                        <div class="itme-disct-left">
                            <a href="#"  @click="downloadFile(item)">
                                <i class="el-icon-download"></i>
                            </a>
                            <i class="el-icon-view"></i>
                            <!-- <i><b>25MG</b></i> -->
                        </div>
                        <div class="itme-disct-right">
                            <img :src="appURL + '/' + item.path" />
                        </div>
                    </div>
                    <div class="name-distc">Fayil: <b>{{ item.original_name }}</b></div>
                </div>
            </div>
            </div>
            <div v-show="!isVisible">
                <el-row :gutter="30">
                <el-col :span="6">
                    <el-button @click="showImage = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>{{ title }} fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
                <el-col :span="6">
                    <el-button @click="showSeriesNumber = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>Seriya va raqami fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
                <el-col :span="6">
                    <el-button @click="showPassport = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>Passport fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
                <el-col :span="6">
                    <el-button @click="showCertificate = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>Sertifikat fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
                <el-col :span="6">
                    <el-button @click="showOwnership = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>Mulk egaligi fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
                <el-col :span="6">
                    <el-button @click="showAnotherCertificate = true">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc"><b>Boshqa sertifikat fotosurati</b></div>
                        </div>
                    </el-button>
                </el-col>
            </el-row>
            </div>
        </div>
        <template>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showImage"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-image
                    :images="images"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-image>
            </el-drawer>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showSeriesNumber"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-series-number
                    :images="seriesNumber"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-series-number>
            </el-drawer>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showPassport"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-passport
                    :images="passport"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-passport>
            </el-drawer>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showCertificate"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-certificate
                    :images="certificate"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-certificate>
            </el-drawer>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showOwnership"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-ownership
                    :images="ownerships"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-ownership>
            </el-drawer>
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showAnotherCertificate"
                size="90%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-another-certificate
                    :anothers="another_certificate"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-another-certificate>
            </el-drawer>
        </template>
    </div>
</template>

<script>
import drawer from '@/utils/mixins/drawer';
import { i18n } from "@/utils/i18n";
import { mapActions } from 'vuex';
import crmImage from './components/images';
import crmSeriesNumber from './components/series_and_number';
import crmPassport from './components/passport_img';
import crmCertificate from './components/certificate';
import crmOwnership from './components/ownership';
import crmAnotherCertificate from './components/anotherCertificate';
    export default {
        mixins: [drawer],
        components: {crmImage, crmSeriesNumber, crmPassport, crmCertificate, crmOwnership, crmAnotherCertificate},
        props: {
            selectedItem: {
                type: Object
            }
        },
        data(){
            return {
                appURL: process.env.VUE_APP_URL_DOCS,
                isVisible: false,
                building_inform: [],
                document_type: '',
                isShow: false,
                title: '',
                showImage: false,
                images: [],
                showSeriesNumber:false,
                seriesNumber: [],
                showPassport: false,
                passport: [],
                showCertificate: false,
                certificate: [],
                showOwnership: false,
                ownerships: [],
                showAnotherCertificate: false,
                another_certificate: []
            }
        },
        methods: {
            ...mapActions({
                getDocuments: 'documents/getDocuments',
                download_file: 'documents/downloadFile'
            }),
            afterOpen(){
                this.load();
            },
            load(){
                if (!this.loadingData) {
                    let query = {};
                    if (this.selectedItem.document_type === 'information') {
                        let document_type = this.selectedItem.hasOwnProperty('clinic') ? 'clinic' : 'filial';
                        query['document_type'] = document_type;
                    }
                    else if(this.selectedItem.document_type === 'technique'){
                        query['document_type'] = 'technique';
                    }else{
                        query['document_type'] = 'equipment';
                    }
                    query['model_id'] = this.selectedItem.id;

                    this.getDocuments(query).then((res) => {
                        this.isShow = true;
                        this.isVisible = _.isArray(res.data) ? true : false;
                        this.document_type = res.document_type;
                        if (this.isVisible) {
                            res.data.forEach( elem => {
                                elem.file_items.forEach(item => {
                                     this.building_inform.push({
                                        id: item.id,
                                        original_name: item.original_name,
                                        path: item.path,
                                        image_type: this.document_type
                                  });
                                });
                            });  
                        }
                        else{
                            this.title = (this.document_type === 'technique') ? 'Texnika' : 'Uskuna';
                            res.data.images.forEach((elem, index) => {
                                this.images.push({
                                    id: elem.id,
                                    original_name: elem.original_name,
                                    path: elem.path,
                                    image_type: 'image'
                                });
                            }); 
                            res.data.series_and_number_images.forEach((elem, index) => {
                                this.seriesNumber.push({
                                    id: elem.id,
                                    original_name: elem.original_name,
                                    path: elem.path,
                                    image_type: 'series_and_number'
                                });
                            }); 
                            res.data.passport_images.forEach((elem, index) => {
                                this.passport.push({
                                    id: elem.id,
                                    original_name: elem.original_name,
                                    path: elem.path,
                                    image_type: 'passport'
                                });
                            }); 
                            res.data.certificate_images.forEach((elem, index) => {
                                this.certificate.push({
                                    id: elem.id,
                                    original_name: elem.original_name,
                                    path: elem.path,
                                    image_type: 'certificate'
                                });
                            });
                            res.data.ownership_images.forEach((elem, index) => {
                                this.ownerships.push({
                                    id: elem.id,
                                    original_name: elem.original_name,
                                    path: elem.path,
                                    image_type: 'ownership'
                                });
                            });
                            res.data.another_certificate.forEach((elem, index) => {
                                this.another_certificate.push({
                                    id: elem.id,
                                    name: elem.name,
                                    albums: _.map(elem.albums, album => {
                                        return {
                                            id: album.id,
                                            original_name: album.original_name,
                                            path: album.path,
                                            image_type: 'another_certificate'
                                        }
                                    }),
                                });
                            });
                        }
                    }).catch((err) => {
                        
                    });
                }
            },
            downloadFile(file){    
                let fileName = file.original_name;
                let query = {file_id: file.id, document_type: file.image_type};
                this.download_file(query).then((res) => {
                    const file_url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/*'}));
                    const file_link = document.createElement('a');
                    file_link.href = file_url;
                    file_link.setAttribute('download', fileName);
                    document.body.appendChild(file_link);
                    file_link.click();
                }).catch((err) => {
                    console.log(err.response.data);
                });
                // let split_el = _.split(res.data.type, '/', 2);
                // let hasfileName = (fileName) ? fileName : (split_el[0] + '.' + split_el[1]); 
            },
            drawerOpened(ref) {
                if (this.$refs[ref]) {
                    if (_.isFunction(this.$refs[ref].opened)) {
                        this.$refs[ref].opened()
                    }
                }
            },
            afterLeave(){
                this.building_inform = [];   
                this.images = [];
                this.seriesNumber = [];
                this.passport = [];
                this.certificate = [];
                this.ownerships = [];
                this.another_certificate = [];
                this.isShow = false;
            },
            
        },
        
    }
</script>

<style>

</style>