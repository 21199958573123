<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Hujjatlar</div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                v-model="filterForm.search"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <!-- <router-link :to="{ path: '/documentAdd' }">
                        <el-button>Hujjatlar qo'shish</el-button>
                    </router-link> -->
                    <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            size="small"
                            icon="el-icon-setting"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(column, index) in columns"
                                :key="index"
                            >
                                <el-checkbox
                                    :checked="column.show"
                                    @change="column.show = !column.show"
                                    >{{ column.title }}</el-checkbox
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item> Hujjatlar </el-breadcrumb-item>
                <el-breadcrumb-item> Litsenziya Hujjatlar </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.clinic.show">
                            {{ columns.clinic.title }}
                        </th>

                        <th v-if="columns.filial.show">
                            {{ columns.filial.title }}
                        </th>

                        <th v-if="columns.region.show">
                            {{ columns.region.title }}
                        </th>

                        <th v-if="columns.document_type.show">
                            {{ columns.document_type.title }}
                        </th>

                        <th v-if="columns.file_name.show">
                            {{ columns.file_name.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                         <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                                disabled
                            ></el-input>
                        </th>

                        <th v-if="columns.clinic.show">
                           <select-clinic
                           :id="filterForm.clinic_id"
                           v-model="filterForm.clinic_id"
                           :size="'mini'"
                           ></select-clinic>
                        </th>

                        <th v-if="columns.filial.show">
                            <select-filial
                            :size="'mini'"
                            :disabled="true"
                            >
                            </select-filial>
                        </th>

                        <th v-if="columns.region.show">
                            <select-region
                            :id="filterForm.region_id"
                            v-model="filterForm.region_id"
                            :size="'mini'"
                            ></select-region>
                        </th>

                        <th v-if="columns.document_type.show">
                           <el-select v-model="filterForm.document_type" :placeholder="columns.document_type.title" size="mini" filterable clearable>
                                <el-option label="Bino inshoat ma'lumotlari" value="information"></el-option>
                                <el-option label="Texnika ma'lumotlari" value="technique"></el-option>
                                <el-option label="Uskuna ma'lumotlari" value="equipment"></el-option>
                            </el-select>
                        </th>

                        <th v-if="columns.file_name.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.file_name"
                                :placeholder="columns.file_name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.created_at.show">
                            <el-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                                size="mini"
                                disabled
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <el-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                                size="mini"
                                disabled
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>
                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="(item, index) in all_informations"
                        :key="index"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ item.key + 1 }}</td>

                        <td v-if="columns.clinic.show">
                              <span v-if="item.clinic.parent">
                                {{ item.clinic.parent ? item.clinic.parent.brand_name : '' }}
                            </span>
                            <span v-else>
                                {{ item.clinic ? item.clinic.brand_name : '' }}
                            </span> 
                        </td>

                        <td v-if="columns.filial.show">
                            <span v-if="item.clinic.parent">
                                {{ item.clinic ? item.clinic.brand_name : '' }}
                            </span>
                            <span v-else>{{''}}</span>
                        </td>

                        <td v-if="columns.region.show">
                            <span v-if="item.clinic">
                                {{ item.clinic.region ? item.clinic.region.region_name : '' }}
                             </span>
                            <span v-else-if="item.filial">
                                {{ item.filial.region ? item.filial.region.region_name : '' }}
                            </span>    
                        </td>

                        <td v-if="columns.document_type.show">
                            {{ $t('message.'+ item.document_type) }}
                        </td>

                        <td v-if="columns.file_name.show">
                            <span v-if="item.hasOwnProperty('document_name')">
                            {{ item.document_name ? item.document_name : '' }}
                            </span>
                            <span v-else-if="item.hasOwnProperty('name')">
                                {{ item.name ? item.name : '' }}
                            </span>
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ item.created_at }}
                        </td>

                         <td v-if="columns.updated_at.show">
                            {{ item.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-button type="text" @click="showInformation(item)">{{ $t('message.view')}}</el-button>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
                >
                </crm-pagination>
            </div>
            <template>
                <el-drawer
                    :append-to-body="true"
                    :with-header="false"
                    :visible.sync="showDialog"
                    size="95%"
                    ref="drawerShow"
                    @opened="drawerOpened('drawerShowChild')"
                    @closed="drawerClosed('drawerShowChild')"
                >
                    <crm-show
                        :selectedItem="selectedItem"
                        ref="drawerShowChild"
                        drawer="drawerShow"
                    ></crm-show>
                </el-drawer>
            </template>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import SelectClinic from '@/components/filters/inventory/select-clinic';
import SelectRegion from '@/components/filters/inventory/select-region';
import list from '@/utils/mixins/list';
import crmShow from './show';
export default {
    name: "documents",
    mixins: [list],
    components: {SelectClinic,  SelectRegion, crmShow },
    computed:{
            ...mapGetters({
                columns: 'documents/columns',
                filter: 'documents/filter',
                all_informations: 'documents/all_informations',
                pagination: 'documents/pagination'
             }),
    },
    data() {
        return {
            showDialog: false
        };
    },
    methods: {
        ...mapActions({
            updateList: "documents/index",
            setPagination: "documents/setPagination",
            updateSort: "documents/updateSort",
            updateFilter: "documents/updateFilter",
            updateColumn: "documents/updateColumn",
            updatePagination: "documents/updatePagination",
            show: "documents/show",
            empty: "documents/empty",
            delete: "documents/destroy",
            refreshData: "documents/refreshData",
        }),
        showInformation(data){
            this.showDialog = true;
            this.selectedItem = data;
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("documents/EMPTY_LIST");
        next();
    },
};
</script>
